import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment.prod';
import {UntilDestroy} from '@ngneat/until-destroy';
import {UserStateService} from '../../services/user-state.service';
import {BehaviorSubject} from 'rxjs';
import {fadeTransition} from '../../animations/fade-in-animation';
import {ModalService} from '../../services/modal.service';
import {MobileDetectionService} from '../../services/mobile-detection.service';
import {ContentLocationService} from '../../services/content-location.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'sbz-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit, OnDestroy {
  flowId = environment.descopeFlowId;
  activeStep: number = 1;
  submitForm = false;
  hideDescope$ = new BehaviorSubject<boolean>(true);
  user$ = this.userStateService.user$;
  userNeedToRegister$ = this.userStateService.userNeedToRegister$;

  private descopeSocialLoginContainer = '#PN5ZN_viow';

  constructor(private userStateService: UserStateService,
              private mobileDetectionService: MobileDetectionService,
              private contentLocationService: ContentLocationService,
              private modalService: ModalService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.contentLocationService.changeMainContentLocation(240);
  }

  ngOnDestroy() {
    this.contentLocationService.resetMainContentLocation();
  }

  onSuccess(event: CustomEvent) {
    setTimeout(() => {
      this.userStateService.updateUserFromDescope(event.detail.sessionJwt, event.detail.user.picture);
      this.mobileDetectionService.isMobile$
        .subscribe({
          next: isMobile => this.onReady()
        });
    });
  }

  onError(event: CustomEvent) {
    this.modalService.openErrorModal('An error occurred while retrieving user state. Please refresh the page.');
    console.error('ERROR FROM LOG IN FLOW', event.detail);
  }

  onReady() {
    const descope = document.querySelector('descope-wc')!;
    const wcRoot = descope.shadowRoot?.getElementById('wc-root')!;
    const mainContainer: HTMLElement = wcRoot.querySelector('descope-container')!;
    mainContainer.style.width = '100%';
    mainContainer.style.marginLeft = '-1rem';
    mainContainer.style.marginRight = '-1rem';
    mainContainer.style.marginTop = '-1rem';
    const descopeLoginButtonsContainer = mainContainer.querySelector(this.descopeSocialLoginContainer);

    if (descopeLoginButtonsContainer) {
      this.setLoginButtonsStyle(descopeLoginButtonsContainer);
      this.hideDescope$.next(false);
    }
  }

  changeActiveStep(activeStep: number) {
    this.activeStep = activeStep;
    this.cdr.detectChanges();
  }

  submit() {
    this.submitForm = true;
  }

  private setLoginButtonsStyle(descopeLoginButtonsContainer: Element) {
    let fontSize = this.mobileDetectionService.isMobile$.getValue() ? 16 : 24.5;

    const loginButtonsCss = `
        width: 100%;
        border-radius: 42px;
        border-color: rgba(37, 41, 49, 0.3);
        font-size: ${fontSize}px;
        font-weight: 500;
        color: #252931;
    `;
    const googleButton: HTMLElement = descopeLoginButtonsContainer.querySelector('descope-button[data-descope-provider="google"]')!;
    googleButton.style.cssText = `${loginButtonsCss} color: #252931;`;

    const facebookButton: HTMLElement = descopeLoginButtonsContainer.querySelector('descope-button[data-descope-provider="facebook"]')!;
    facebookButton.style.cssText = `${loginButtonsCss} color: white; background-color: #4285f4;`;
  }
}
