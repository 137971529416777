@if (isRobotVisible$ | async) {
  <div #robotContainer @bounce [@.disabled]="!(shouldBounce$ | async)" class="robot-container robot-center"
       [ngClass]="[(robotTransform$ | async), (robotPosition$ | async)]">
    <div class="robot-background relative">
      @if (isLoading$ | async) {
        <div class="loader"></div>
      } @else {
        @if ((robotImageSrc$ | async)) {
          <img [src]="(robotImageSrc$ | async)" class="h-auto max-w-full" alt="image">
        } @else {
          <div class="glass left"></div>
          <div class="glass-line"></div>
          <div class="glass right"></div>
          <div #leftEye class="open-eye left animation-blink" [ngClass]="(robotAnimation$ | async)"></div>
          <div #rightEye class="open-eye right animation-blink" [ngClass]="(robotAnimation$ | async)"></div>
        }
      }
    </div>
  </div>
}
