import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {ContentLocationService} from '../../../services/content-location.service';
import {fadeTransition} from '../../../animations/fade-in-animation';
import {UserStateService} from '../../../services/user-state.service';

@Component({
  selector: 'sbz-distribute',
  templateUrl: './distribute.component.html',
  styleUrl: './distribute.component.scss',
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DistributeComponent implements OnInit, OnDestroy {
  constructor(private contentLocationService: ContentLocationService,
              private userStateService: UserStateService) {
  }

  ngOnInit() {
    this.contentLocationService.changeMainContentLocation(280);
  }

  ngOnDestroy() {
    this.contentLocationService.resetMainContentLocation();
  }

  checkLogin() {
    this.userStateService.checkUserInfoAndNavigate();
  }
}
