import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {ContentLocationService} from '../../../services/content-location.service';
import {fadeTransition} from '../../../animations/fade-in-animation';
import {articles} from '../../../interfaces/article';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'sbz-help-and-support',
  templateUrl: './help-and-support.component.html',
  styleUrl: './help-and-support.component.scss',
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpAndSupportComponent implements OnInit, OnDestroy {
  articles = articles;
  iframeLink: string | null = null;

  constructor(private contentLocationService: ContentLocationService,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.contentLocationService.changeMainContentLocation(280, 100);
  }

  ngOnDestroy() {
    this.contentLocationService.resetMainContentLocation();
  }

  sanitizedUrl(url: string): SafeResourceUrl {
    const videoId = new URL(url).searchParams.get('v');
    const safeUrl = `https://www.youtube.com/embed/${videoId}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(safeUrl);
  }
}
