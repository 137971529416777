import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ContentLocationService} from '../../services/content-location.service';
import {MenuComponent} from '../menu/menu.component';
import {UserStateService} from '../../services/user-state.service';
import {slideMenu} from '../../animations/slide-menu-animation';
import {LoadingService} from '../../services/loading.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'sbz-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  animations: [slideMenu],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent {
  mainContentHeight$ = this.contentLocationService.mainContentHeight$;
  mainContentTop$ = this.contentLocationService.mainContentTop$;
  user$ = this.userStateService.user$;
  isLoading$ = this.loadingService.isLoading$;

  isMenuOpen: boolean = false;
  @ViewChild(MenuComponent) menuComponent!: MenuComponent;

  constructor(private contentLocationService: ContentLocationService,
              private userStateService: UserStateService,
              private loadingService: LoadingService,
              private router: Router) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      window.scrollTo(0, 0);
    });
  }

  onMenuToggle(isMenuOpen: boolean) {
    this.isMenuOpen = isMenuOpen;
  }

  toggleMenu() {
    this.menuComponent.toggleMenu();
  }

  logout() {
    this.userStateService.logoUt();
  }
}
