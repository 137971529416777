import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ModalService} from '../../../services/modal.service';
import {fadeTransition} from '../../../animations/fade-in-animation';
import {BehaviorSubject} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment.prod';
import {UserStateService} from '../../../services/user-state.service';
import {SubscriptionPackage} from '../../../interfaces/subscriptionPackage';
import {SubscriptionService} from '../../../services/subscription.service';

@Component({
  selector: 'sbz-coupon-modal',
  templateUrl: './coupon-modal.component.html',
  styleUrl: './coupon-modal.component.scss',
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouponModalComponent {
  isCouponModalVisible$ = this.modalService.isCouponModalVisible$;
  couponIsInvalid$ = new BehaviorSubject<boolean>(false);
  isLoading$ = new BehaviorSubject<boolean>(false);
  subscriptionPackage: SubscriptionPackage | null = null;

  couponForm = new FormGroup({
    couponCode: new FormControl('')
  });

  constructor(private modalService: ModalService,
              private httpClient: HttpClient,
              private userStateService: UserStateService,
              private subscriptionService: SubscriptionService) {
  }

  checkCoupon() {
    const sessionToken = this.userStateService.sessionToken$.getValue();
    this.isLoading$.next(true);
    const couponCode = this.couponForm.get('couponCode');

    if (couponCode?.value?.trim() !== '') {
      const url = `${environment.baseUrl}/subscriptions/packages?couponId=${couponCode!.value}`;
      const options = {
        headers: {'Authorization': `Bearer ${sessionToken}`}
      };

      this.httpClient.get(url, options)
        .subscribe({
          next: (data: any) => {
            const packages = this.subscriptionService.setAndSortPackages(data);

            this.subscriptionPackage = packages[1];
            this.isLoading$.next(false);
          },
          error: (error) => {
            this.couponIsInvalid$.next(true);
            this.isLoading$.next(false);
          }
        });
    } else {
      this.isLoading$.next(false);
      this.closeModal();
    }
  }

  choosePackage(packageId: string) {
    this.subscriptionService.packageIdFromCoupon$.next(packageId);
    this.closeModal();
  }

  closeModal() {
    this.couponForm.get('couponCode')?.reset();
    this.isLoading$.next(false);
    this.couponIsInvalid$.next(false);
    this.subscriptionPackage = null;
    this.modalService.closeCouponModal();
  }
}
