import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {UserStateService} from '../../services/user-state.service';
import {fadeTransition} from '../../animations/fade-in-animation';
import {highlightActive} from '../../animations/highlight-active-animation';
import {User} from '../../interfaces/user';
import {LoadingService} from '../../services/loading.service';

@Component({
  selector: 'sbz-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
  animations: [fadeTransition, highlightActive],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterComponent implements OnInit, OnChanges {
  @Input() activeStep: number = 1;
  @Input() submitForm = false;
  @Output() forceStepChange = new EventEmitter<number>();
  form!: FormGroup;
  userImage: string | undefined;
  isLoading$ = this.loadingService.isLoading$;

  constructor(private fb: FormBuilder,
              private userStateService: UserStateService,
              private loadingService: LoadingService) {
  }

  ngOnInit(): void {
    const user = this.userStateService.user$.getValue();
    this.form = this.fb.group({
      fullName: [user?.displayName, Validators.required],
      phone: [user?.phone, [Validators.required, this.phoneNumberValidator()]],
      email: [user?.email, [Validators.required, Validators.email]],
      role: ['', [Validators.required, this.roleValidator()]],
      youtubeChannel: ['', [this.urlValidator()]],
      instagramUrl: ['', [this.urlValidator()]],
      spotifyArtistPage: ['', [this.urlValidator()]],
      appleMusicProfile: ['', [this.urlValidator()]],
      description: ['']
    });
    this.userImage = user?.pictureUrl;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['activeStep']) {
      switch (this.activeStep) {
        case 2:
          if (!this.areFormStepValid(['fullName', 'phone', 'email', 'role'])) {
            this.forceStepChange.emit(1);
          }
          break;
        case 3:
          if (!this.areFormStepValid(['youtubeChannel', 'instagramUrl', 'spotifyArtistPage', 'appleMusicProfile'], true)) {
            this.forceStepChange.emit(2);
          }
          break;
      }
    }
    if (changes['submitForm'] && this.submitForm) {
      this.onSubmit();
    }
  }

  onSubmit() {
    const user: User = this.form.value;
    user.token = this.userStateService.user$.getValue()!.token;
    user.pictureUrl = this.userImage || '';
    user.finishedRegistration = true;
    this.userStateService.updateUserInfo(user);
  }

  getErrorForField(fieldName: string): boolean {
    const control = this.form.get(fieldName);
    return !!(control && control.errors && control.touched);
  }

  base64ImageCreated(userImage: string) {
    this.userImage = userImage;
  }

  private phoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // This regex allows digits, plus signs, and minus signs. Adjust as needed.
      const valid = /^[0-9+-]+$/.test(control.value);
      return valid ? null : {'invalidPhoneNumber': {value: control.value}};
    };
  }

  private roleValidator(): ValidatorFn {
    const validRoles = ['Artist', 'Artist Manager', 'Distributor'];
    return (control: AbstractControl): { [key: string]: any } | null => {
      return validRoles.includes(control.value) ? null : {'invalidRole': {value: control.value}};
    };
  }

  private urlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // This regex is a basic pattern for matching URLs. It's simplified for demonstration purposes.
      // You might need a more comprehensive pattern depending on your validation needs.
      const pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
      const valid = pattern.test(control.value);
      return valid ? null : {'invalidUrl': {value: control.value}};
    };
  }

  private areFormStepValid(fields: string[], checkValue = false): boolean {
    let allFieldsValid = true;

    fields.forEach(field => {
      const control = this.form.get(field);
      const shouldCheckValidity = control && (!checkValue || (checkValue && control.value));
      if (shouldCheckValidity && control.invalid) {
        allFieldsValid = false;
        // Mark the control as touched to trigger the display of error messages
        control.markAsTouched();
      }
    });

    return allFieldsValid;
  }
}
