import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {ContentLocationService} from '../../services/content-location.service';
import {fadeTransition} from '../../animations/fade-in-animation';

@Component({
  selector: 'sbz-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit, OnDestroy {

  constructor(private contentLocationService: ContentLocationService) {
  }

  ngOnInit() {
    this.contentLocationService.changeMainContentLocation(280);
  }

  ngOnDestroy() {
    this.contentLocationService.resetMainContentLocation();
  }
}
