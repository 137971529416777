<div [@fadeTransition] [ngClass]="{'payment-showing': goToPayment$ | async}"
     class="subscription-container">
  @if (goToPayment$ | async) {
    <section class="white-container w-full">
      <p class="text-center font-prop-normal font-bold lg:-mt-[20px] -mt-[36px] xl:text-[38px] lg:text-[30px] text-[19.5px]">
        Almost There!
      </p>
      <p class="text-center font-prop-normal font-light mb-[41px] lg:px-0 px-4 xl:text-[20.5px] lg:text-[20px] text-[14px]">
        Enter your payment details to unlock your musical potential with Dstro.
      </p>
      <img ngSrc="assets/images/credit-cards.webp" alt="credit cards" height="59" width="376"
           class="hidden lg:block mx-auto">
      <img ngSrc="assets/images/credit-cards.webp" alt="credit cards" height="31" width="192"
           class="block lg:hidden mx-auto">
      <form>
        <div class="flex flex-col w-full">
          <div class="w-full px-3 relative xl:mb-[35px] lg:mb-[27px] mb-[19px]">
            <div #cardNumber class="input-style placeholder-[#25293166]"></div>
          </div>

          <!-- Expiration Date and CVV -->
          <div class="flex flex-wrap lg:mb-0 mb-[19px]">
            <div class="w-full lg:w-1/2 px-3 relative mb-[19px]">
              <div #cardExpiry class="input-style placeholder-[#25293166]"></div>
            </div>
            <div class="w-full lg:w-1/2 px-3 relative">
              <div #cardCvc class="input-style placeholder-[#25293166]"></div>
            </div>
          </div>
        </div>
      </form>
    </section>
  } @else {
    <section class="w-full lg:mt-[20px] sm:mt-[40px]">
      <section class="main-title-container mb-[20px] sm:mb-[40px] lg:mb-0">
        <p class="hidden lg:block main-text font-prop-normal">
          {{ (existingPackageId$ | async) ? 'Change' : 'Pick' }} the <strong class="font-bold">Dstro Package
          That's</strong> Perfect for
          you</p>
        <p class="block lg:hidden main-text font-prop-normal mb-[12px] sm:mb-[20px]">
          {{ (existingPackageId$ | async) ? 'Change' : 'Pick' }} the Perfect <strong class="font-bold">Dstro
          Package</strong></p>
        <button
            class="main-button-design gradient-button subscription-button xl:!w-[155px] lg:!w-[125px]
            max-w-[310px] sm:max-w-[500px] !w-[80%] !mb-0" (click)="enterCoupon()">
          BUY WITH COUPON
        </button>
      </section>
      <section class="subscription-packages">
        @for (subscriptionPackage of (subscriptionPackages$ | async); track subscriptionPackage.packageId) {
          <div class="package" [class.selected]="(existingPackageId$ | async) === subscriptionPackage.userType">
            <div class="type">
              <span class="type__user">{{ subscriptionPackage.userType }}</span>
              @if ((existingPackageId$ | async) === subscriptionPackage.userType) {
                <span class="type__chosen" [class.recommended]="subscriptionPackage.isRecommended">My Package</span>
              }
            </div>

            <div class="total">
              <span class="total__currency">{{ subscriptionPackage.currency }}</span>
              <span class="total__amount" [class.recommended]="subscriptionPackage.isRecommended">
                {{ subscriptionPackage.amount }}</span>

              <div class="flex flex-col justify-center">
                <span class="total__interval">/ {{ subscriptionPackage.recurringInterval }}</span>
                <span class="total__credits">{{ subscriptionPackage.totalCredits }}</span>
              </div>
            </div>

            <div class="description">
              <ul>
                @for (line of subscriptionPackage.extraInfo; track line) {
                  <li [ngClass]="{'!font-bold': line.includes('Perks')}">{{ line }}</li>
                }
              </ul>
            </div>
            <button class="main-button-design black-button subscription-button"
                    [ngClass]="{'gradient-button': subscriptionPackage.isRecommended}"
                    (click)="choosePackage(subscriptionPackage.packageId)">CHOOSE PACKAGE
            </button>
          </div>
        }
        <div class="package">
          <div class="type">
            <span class="type__user">Custom</span>
          </div>
          <div class="total">
            <span class="total__custom">Call us</span>
          </div>
          <div class="description">
            <span class="description__custom-text">Migrate your entire song catalog, or a large amount of songs.
              <br>For more details:
            </span>
          </div>
          <button class="main-button-design black-button subscription-button" [routerLink]="'/contact'">Contact Us
          </button>
        </div>
      </section>
    </section>
  }
</div>

@if (goToPayment$ | async) {
  <div [@fadeTransition] class="w-full flex justify-center xl:mt-[34px] mt-[20px]">
    <div class="flex justify-between xl:px-[60px] xl:w-[845px] lg:w-[700px] lg:px-[20px] w-[90%]">
      <button class="main-button-design black-button xl:!w-[185px] lg:!w-[148px] sm:!w-[217px] !w-[101px]"
              (click)="backToPackages()">BACK
      </button>
      <button class="main-button-design gradient-button xl:!w-[185px] lg:!w-[148px] sm:!w-[217px] !w-[101px]"
              (click)="onSubmit()">DONE
      </button>
    </div>
  </div>
}
