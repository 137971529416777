import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {ContentLocationService} from '../../services/content-location.service';
import {UserStateService} from '../../services/user-state.service';
import {fadeTransition} from '../../animations/fade-in-animation';

@Component({
  selector: 'sbz-welcome',
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss',
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeComponent implements OnInit, OnDestroy {
  constructor(private contentLocationService: ContentLocationService,
              private userStateService: UserStateService) {
  }

  ngOnInit() {
    this.contentLocationService.changeMainContentLocation(280);
  }

  ngOnDestroy() {
    this.contentLocationService.resetMainContentLocation();
  }

  checkLogin() {
    this.userStateService.checkUserInfoAndNavigate();
  }
}
