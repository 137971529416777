import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {fadeTransition} from '../../../../animations/fade-in-animation';
import {ContentLocationService} from '../../../../services/content-location.service';

@Component({
  selector: 'sbz-read-more',
  templateUrl: './read-more.component.html',
  styleUrl: './read-more.component.scss',
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReadMoreComponent implements OnInit, OnDestroy {
  constructor(private contentLocationService: ContentLocationService) {
  }

  ngOnInit() {
    this.contentLocationService.changeMainContentLocation(200, 50);
  }

  ngOnDestroy() {
    this.contentLocationService.resetMainContentLocation();
  }
}
