import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {RobotStateService} from './robot-state.service';

@Injectable({
  providedIn: 'root'
})
export class ContentLocationService {
  // routerHeight$ = new BehaviorSubject<string>('calc(100vh - 124px)');
  mainContentHeight$ = new BehaviorSubject<string>('calc(100vh - 124px)');
  mainContentTop$ = new BehaviorSubject<string>('124px');
  currentBreakpoint$ = new BehaviorSubject<string>('');
  private breakpoints = {
    mb: 150, // min-width
    sm: 768, // min-width,
    md: 1024, // min-width
    lg: 1280, // min-width
    xl: 1440, // min-width
    '2xl': 1536, // min-width
    '3xl': 1920 // min-width
  };
  private currentValue: number = 0;
  private currentValueToSubtract: number = 0;

  constructor(private breakpointObserver: BreakpointObserver,
              private robotStateService: RobotStateService) {
    this.detectBreakpoint();
    this.watchWindowSize();
  }

  changeMainContentLocation(value: number, valueToSubtract = 0) {
    this.currentValue = value;
    this.currentValueToSubtract = valueToSubtract;
    setTimeout(() => {
      // this.routerHeight$.next(`calc(100% - ${value + 12}px)`);
      this.mainContentHeight$.next(`calc(100vh - ${this.getValueAfterBreakPointsChanges(value, valueToSubtract)}px)`);
      this.mainContentTop$.next(`${this.getValueAfterBreakPointsChanges(value, valueToSubtract)}px`);
    }, 0);
  }

  resetMainContentLocation() {
    setTimeout(() => {
      // this.routerHeight$.next(`calc(100% - ${value + 12}px)`);
      this.mainContentHeight$.next('calc(100vh - 124px)');
      this.mainContentTop$.next('124px');
    }, 0);
  }

  private detectBreakpoint() {
    const width = window.innerWidth;
    // Reverse the order to match the largest possible breakpoint first
    const breakpointKeys = Object.keys(this.breakpoints).reverse() as Array<keyof typeof this.breakpoints>;
    const foundBreakpoint =
      breakpointKeys.find(key => width >= this.breakpoints[key]) || 'sm'; // Default to 'sm' if none match
    this.currentBreakpoint$.next(foundBreakpoint);
    this.changeRobotTransform(foundBreakpoint);
  }

  private watchWindowSize() {
    this.breakpointObserver.observe([
      `(min-width: ${this.breakpoints.mb}px)`,
      `(min-width: ${this.breakpoints.sm}px)`,
      `(min-width: ${this.breakpoints.md}px)`,
      `(min-width: ${this.breakpoints.lg}px)`,
      `(min-width: ${this.breakpoints.xl}px)`,
      `(min-width: ${this.breakpoints['2xl']}px)`,
      `(min-width: ${this.breakpoints['3xl']}px)`
    ]).subscribe((state: BreakpointState) => {
      this.detectBreakpoint();
      this.changeMainContentLocation(this.currentValue, this.currentValueToSubtract);
    });
  }

  private getValueAfterBreakPointsChanges(value: number, valueToSubtract = 0): number {
    const absoluteValue = Math.abs(value);
    const currentBreakpoint = this.currentBreakpoint$.getValue();
    if (valueToSubtract === 0) {
      switch (currentBreakpoint) {
        case 'mb':
          valueToSubtract = 180;
          break;
        case 'sm':
          valueToSubtract = 150;
          break;
        case 'md':
          valueToSubtract = 110;
          break;
        case 'lg':
          valueToSubtract = 70;
          break;
        case 'xl':
        case '2xl':
        case '3xl':
        default:
          break;
      }
    } else {
      switch (currentBreakpoint) {
        case 'mb':
          valueToSubtract = valueToSubtract + 80;
          break;
        case 'sm':
          valueToSubtract = valueToSubtract + 50;
          break;
        case 'md':
          valueToSubtract = valueToSubtract + 10;
          break;
        case 'lg':
        case 'xl':
        case '2xl':
        case '3xl':
        default:
          break;
      }
    }

    return absoluteValue - valueToSubtract; // Subtract 60 from the absolute value
  }

  private changeRobotTransform(foundBreakpoint: 'xl' | 'mb' | '2xl' | 'md' | '3xl' | 'sm' | 'lg') {
    let isSmOrBigger = true;
    switch (foundBreakpoint) {
      case 'sm':
      case 'md':
      case 'lg':
      case 'xl':
      case '2xl':
      case '3xl':
        isSmOrBigger = true;
        break;
      default:
        isSmOrBigger = false;
    }
    this.robotStateService.robotTransform$.next(isSmOrBigger ? 'sm:!transform-custom-sm' : '!transform-custom-base');
  }
}
