import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {RobotAnimation, RobotImageSrc, RobotPosition} from '../interfaces/robot-animation';

@Injectable({
  providedIn: 'root'
})
export class RobotStateService {
  isRobotVisible$ = new BehaviorSubject<boolean>(true);
  robotAnimation$ = new BehaviorSubject<RobotAnimation>(RobotAnimation.None);
  robotPosition$ = new BehaviorSubject<RobotPosition>(RobotPosition.Center);
  robotImageSrc$ = new BehaviorSubject<RobotImageSrc | null>(null);
  robotTransform$ =
    new BehaviorSubject<'!transform-custom-base' | 'sm:!transform-custom-sm'>('!transform-custom-base');

  toggleRobot(isVisible: boolean) {
    this.isRobotVisible$.next(isVisible);
  }

  changeRobotAnimation(robotAnimation: RobotAnimation) {
    this.robotAnimation$.next(robotAnimation);
  }

  changeRobotPosition(robotPosition: RobotPosition) {
    this.robotPosition$.next(robotPosition);
  }

  resetRobotPosition() {
    this.robotPosition$.next(RobotPosition.Center);
  }

  changeRobotImage(robotImageSrc: RobotImageSrc | null) {
    this.robotImageSrc$.next(robotImageSrc);
  }

  resetRobotImage() {
    this.changeRobotImage(null);
  }
}
