import {Injectable} from '@angular/core';
import {DescopeAuthService} from '@descope/angular-sdk';
import {jwtDecode} from 'jwt-decode';
import {BehaviorSubject} from 'rxjs';
import {User} from '../interfaces/user';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';
import {LoadingService} from './loading.service';
import {ModalService} from './modal.service';

@Injectable({
  providedIn: 'root'
})
export class UserStateService {
  userNeedToRegister$ = new BehaviorSubject<boolean>(true);
  user$ = new BehaviorSubject<User | null>(null);
  sessionToken$ = new BehaviorSubject<string | null>(null);

  constructor(private authService: DescopeAuthService,
              private router: Router,
              private httpClient: HttpClient,
              private loadingService: LoadingService,
              private modalService: ModalService) {
    this.authService.session$.subscribe((session) => {
      if (session.sessionToken) {
        this.updateUserFromDescope(session.sessionToken);
      }
    });
  }

  updateUserFromDescope(sessionToken: string, pictureUrl: string | null = null) {
    this.sessionToken$.next(sessionToken);
    const decodedJwt: any = jwtDecode(sessionToken);
    this.user$.next({
      email: decodedJwt.email,
      phone: decodedJwt.phone,
      displayName: decodedJwt.displayName,
      youtubeChannel: decodedJwt.youtubeChannel,
      instagramMusicProfile: decodedJwt.instagramMusicProfile,
      spotifyProfileLink: decodedJwt.spotifyProfileLink,
      appleMusicProfile: decodedJwt.appleMusicProfile,
      pictureUrl: pictureUrl ? pictureUrl : decodedJwt.pictureUrl,
      finishedRegistration: decodedJwt.finishedRegistration,
      token: decodedJwt.sub,
      userHasSubscribed: decodedJwt.userHasSubscribed,
      selectedPackageId: decodedJwt.selectedPackageId
    });
    const userNeedToRegister = !decodedJwt.email || !decodedJwt.phone || !decodedJwt.finishedRegistration;
    this.userNeedToRegister$.next(userNeedToRegister);

    if (!userNeedToRegister) {
      if (!decodedJwt.userHasSubscribed) {
        this.router.navigate(['/subscribe']).then();
      } else {
        this.router.navigate(['']).then();
      }
    }
  }

  updateUserFromRegister(user: User) {
    this.user$.next(user);
    this.userNeedToRegister$.next(false);
  }

  logoUt() {
    this.authService.descopeSdk.logout();
    this.user$.next(null);
  }

  checkUserInfoAndNavigate() {
    this.loadingService.toggleLoading(true);
    const userNeedToRegister = this.userNeedToRegister$.getValue();
    const user = this.user$.getValue();

    if (user?.userHasSubscribed) {
      this.goToRevelator();
      return;
    }

    this.loadingService.toggleLoading(false);
    if (userNeedToRegister) {
      this.router.navigate(['/login']).then();
      return;
    }

    this.router.navigate(['/subscribe']).then();
  }

  updateUserInfo(user: User) {
    this.loadingService.toggleLoading(true);
    const url = `${environment.baseUrl}/users/${user.token}`;

    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };

    this.httpClient.patch(url, user, options)
      .subscribe({
        next: () => {
          this.updateUserFromRegister(user);
          this.router.navigate(['subscribe']).then();
        },
        error: (err: any) => {
          this.modalService.openErrorModal('We could not update user info. Please try again later.');
        },
        complete: () => {
          this.loadingService.toggleLoading(false);
        }
      });
  }

  goToRevelator() {
    const url = `${environment.baseUrl}/users/revelator-user`;
    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };
    this.httpClient.get(url, options)
      .subscribe({
        next: (data: any) => {
          window.location.href = data.revelatorToken.redirectUrl;
        },
        error: (error) => {
          this.modalService.openErrorModal('We could not find a link to revelator. Please try again later.');
        },
        complete: () => {
          this.loadingService.toggleLoading(false);
        }
      });
  }
}
